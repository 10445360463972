import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/app/",
  },

  {
    path: "/app",
    component: () => import("@/views/Index.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "*",
        component: () => import("@/views/pages/persons/index.vue"),
      },

    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
