import _ from "lodash";
export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    handleServiceException({ dispatch, commit }, { e, router = null }) {

      // return new Promise((resolve, reject) => {
        try {
          const { data, status } = e;
          let errorMessages = [];
          if (status === 404) {
            commit(
              "alert/error",
              {
                showType: "toast",
                position: "bottom-right",
                title: "Error",
                text: data.message,
              },
              { root: true }
            );
          } else if (status === 200) {
            commit(
              "alert/success",
              {
                showType: "toast",
                position: "bottom-right",
                title: "Success",
                text: data.message,
              },
              { root: true }
            );
          } else if (status === 422) {
            errorMessages = _.reduce(
              data.data,
              (errorMessages, tmpData) => {
                errorMessages.push(tmpData.msg);
                return errorMessages;
              },
              []
            );
            commit(
              "alert/setMessage",
              { type: "error", message: _.join(errorMessages, "\r\n") },
              { root: true }
            );
            commit(
              "alert/error",
              {
                showType: "toast",
                position: "bottom-right",
                title: "Error",
                text: _.join(errorMessages, "\r\n"),
              },
              { root: true }
            );
          } 
          else if (status === 401 || status === 403) {
            dispatch("users/sessionExpired", { router }, { root: true });
          }
        } catch (e) {
          
          dispatch(
            "alert/error",
            {
              showType: "toast",
              position: "bottom-right",
              title: "Error",
              text: e.message,
            },
            { root: true }
          );
          console.error(e);
          throw new Error(e);
        }
  
    },
  },
};
