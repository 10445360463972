import Vue from "vue";
import VueI18n from "vue-i18n";
import VueCookies from 'vue-cookies'

import en from "vuetify/lib/locale/en";
import ar from "vuetify/lib/locale/ar";
Vue.use(VueI18n);
var language = window.navigator.userLanguage || window.navigator.language;
var shortLang = language;
if (shortLang.indexOf("-") !== -1) shortLang = shortLang.split("-")[0];

if (shortLang.indexOf("_") !== -1) shortLang = shortLang.split("_")[0];

// alert(shortLang); //works IE/SAFARI/CHROME/FF
const messages = {
  en: {
    ...require("@/locales/en.json"),
    $vuetify: en,
  },
  ar: {
    ...require("@/locales/ar.json"),
    $vuetify: ar,
  }, 
};

if (VueCookies.get("lang") != null)
  shortLang = VueCookies.get("lang")
else shortLang = shortLang
// console.log(VueCookies.get("lang"))
export default new VueI18n({
  locale: shortLang || process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages,
});
