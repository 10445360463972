import Vue from "vue";
import axios from "axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import "./plugins/base";
//custom functions
import GlobalFunction from "./helper/globalFunctions.js";
Vue.prototype.$subStringDate = GlobalFunction.subStringDate;
     
 
 
// router.beforeEach((to, from, next) => {
//   next("/app/index");
// });

axios.defaults.baseURL = process.env.VUE_APP_Api; 
axios.defaults.headers.language = i18n.locale;
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
