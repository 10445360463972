import moment from "moment";


const subStringDate = (value) => {
  if (_.isEmpty(value)) {
    return "invalid date";
  }
  //   .toLocaleTimeString("en-US");
  return new Date(value).toLocaleTimeString("Fr", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    // timeZone: "UTC+3",
    hour12: true,
    hour: "numeric",
    minute: "numeric",
  });
  // return value.substr(0, 19).replace(/^(.{10}).{1}(.*)/, "$1 $2");
};


export default { subStringDate };
