import Vue from "vue";
import Vuex from "vuex";

import alertStore from "./alert/alert";
import APIStore from "./handleRequest/store";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    geoPlace: [],
    readyGeo: false,
    darkMode: false,
    barColor: "white",
    cities: [
      { "id": 1, "city_ar": "دمشق", "city_en": "Damascus" },
      { "id": 2, "city_ar": "حلب", "city_en": "Aleppo" },
      { "id": 3, "city_ar": "حمص", "city_en": "Homs" },
      { "id": 4, "city_ar": "حماة", "city_en": "Hama" },
      { "id": 5, "city_ar": "اللاذقية", "city_en": "Latakia" },
      { "id": 6, "city_ar": "طرطوس", "city_en": "Tartus" },
      { "id": 7, "city_ar": "دير الزور", "city_en": "Deir ez-Zor" },
      { "id": 8, "city_ar": "الرقة", "city_en": "Raqqa" },
      { "id": 9, "city_ar": "الحسكة", "city_en": "Al-Hasakah" },
      { "id": 10, "city_ar": "إدلب", "city_en": "Idlib" },
      { "id": 11, "city_ar": "درعا", "city_en": "Daraa" },
      { "id": 12, "city_ar": "السويداء", "city_en": "As-Suwayda" },
      { "id": 13, "city_ar": "القنيطرة", "city_en": "Quneitra" },
      { "id": 14, "city_ar": "جبلة", "city_en": "Jableh" },
      { "id": 15, "city_ar": "بانياس", "city_en": "Baniyas" },
      { "id": 16, "city_ar": "الميادين", "city_en": "Mayadin" },
      { "id": 17, "city_ar": "البوكمال", "city_en": "Al-Bukamal" },
      { "id": 18, "city_ar": "منبج", "city_en": "Manbij" },
      { "id": 19, "city_ar": "الباب", "city_en": "Al-Bab" },
      { "id": 20, "city_ar": "جرمانا", "city_en": "Jaramana" },
      { "id": 21, "city_ar": "عفرين", "city_en": "Afrin" },
      { "id": 22, "city_ar": "عين العرب", "city_en": "Ayn al-Arab (Kobani)" },
      { "id": 23, "city_ar": "تل أبيض", "city_en": "Tell Abyad" },
      { "id": 24, "city_ar": "الزبداني", "city_en": "Zabadani" },
      { "id": 25, "city_ar": "دوما", "city_en": "Douma" },
      { "id": 26, "city_ar": "التل", "city_en": "Al-Tall" },
      { "id": 27, "city_ar": "الرستن", "city_en": "Ar-Rastan" },
      { "id": 28, "city_ar": "سلمية", "city_en": "Salamiyah" },
      { "id": 29, "city_ar": "مصياف", "city_en": "Masyaf" },
      { "id": 30, "city_ar": "معرة النعمان", "city_en": "Maarrat al-Numan" },
      { "id": 31, "city_ar": "أريحا", "city_en": "Ariha" },
      { "id": 32, "city_ar": "القامشلي", "city_en": "Qamishli" },
      { "id": 33, "city_ar": "رأس العين", "city_en": "Ras al-Ayn" },
      { "id": 34, "city_ar": "دير عطية", "city_en": "Deir Atiyah" },
      { "id": 35, "city_ar": "قطنا", "city_en": "Qatana" },
      { "id": 36, "city_ar": "السقيلبية", "city_en": "As-Suqaylabiyah" },
      { "id": 37, "city_ar": "خان شيخون", "city_en": "Khan Shaykhun" },
      { "id": 38, "city_ar": "قدسيا", "city_en": "Qudsaya" },
      { "id": 39, "city_ar": "الحفة", "city_en": "Al-Haffa" },
      { "id": 40, "city_ar": "نوى", "city_en": "Nawa" },
      { "id": 41, "city_ar": "إنخل", "city_en": "Inkhil" },
      { "id": 42, "city_ar": "النبك", "city_en": "An-Nabk" },
      { "id": 43, "city_ar": "كفر تخاريم", "city_en": "Kafr Takharim" },
      { "id": 44, "city_ar": "تدمر", "city_en": "Palmyra" },
      { "id": 45, "city_ar": "السخنة", "city_en": "As-Sukhna" },
      { "id": 46, "city_ar": "عقربات", "city_en": "Aqrabat" },
      { "id": 47, "city_ar": "كفر بطنا", "city_en": "Kafr Batna" },
      { "id": 48, "city_ar": "حرستا", "city_en": "Harasta" },
      { "id": 49, "city_ar": "داريا", "city_en": "Darayya" },
      { "id": 50, "city_ar": "المعضمية", "city_en": "Al-Moadamiyeh" }
    ]
    ,
    loading: true,
    drawer: null,
    typePlace: [
      { title: "Packet", type: 1 },
      // { title: "Food", type: 2 },
      { title: "Documents", type: 3 },
      { title: "Other", type: 4 },
    ],
  },
  getters: {
    darkMode(state) {
      return state.darkMode;
    },
    getCities(state) {
      return state.cities;
    },
  },

  mutations: {
    changeDarkMode(state, payload) {
      state.darkMode = payload;
      $cookies.set("darkMode", payload);
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    SET_GEOPLACE(state, payload) {
      state.geoPlace = payload;
      state.cities = payload;
      state.loading = false;
    },

  },
  actions: {
    changeDarkMode({ commit }, payload) {
      commit("changeDarkMode", payload);
    },
    getGeoPlace({ state, commit },) {
      return new Promise((resolve, reject) => {

        place
          .list()
          .then((res) => {
            state.readyGeo = false
            if (res.status <= 200) {

              commit("SET_GEOPLACE", res.data.data);
              state.readyGeo = true
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch((err) => {
            state.readyGeo = false
            commit("SET_GEOPLACE", []);
            dispatch("handleServiceException", { e: err }, { root: true });

          });
      });
    },

  },
  modules: {
    namespace: true,
    alert: alertStore,
    api: APIStore,
  },
});




